import React from 'react';
import Figure from './figure'
import Video from './video'
import Decorator from './decorator'

const serializers = {
  types: {
    figure: Figure, 
    video: Video
  },
  marks: {
    link: ({mark, children}) => {
      const { newTab, href } = mark
      return newTab ?
        <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    },
    decorator: ({mark, children}) => {
      return <Decorator mark={mark}/>
    }
  }
}

export default serializers
