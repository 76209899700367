import React from 'react'
import BlockContent from './block-content'
import Container from './container'
import Article from './article'
import ContentSection from './content-section'
import Figure from './figure'
import RelatedProjects from './relatedProjects'
import CountUp from 'react-countup'
import { filterOutListedDocs, filterOutUnlistedDocs } from '../lib/helpers'

function Project (props) {
  const {_rawBody, id, title, year, projectWebsite, categories, mainImage, members, allProjects, unlisted} = props;
  const otherProjects = unlisted ? allProjects.projects.filter(filterOutListedDocs) : allProjects.projects.filter(filterOutUnlistedDocs);
  const imageCount = _rawBody ? _rawBody.filter((block) => block._type === 'figure').length + 1 : undefined; // +1 for the mainImage
  const videoCount = _rawBody ? _rawBody.filter((block) => block._type === 'video').length : undefined;
  const countIncrementDuration = 0.1;
  return (
    <Container>
      <Article>
        <div className="project-header">
          <div className="header-intro">
            <div className="title-container">
              <h1 className="type-body-display-sans project-title">{title}{year && <sup className="type-body"><span className="nbsp">&nbsp;</span><time>{year}</time></sup>}</h1>
            </div>
            <div className="project-stats">
              {imageCount > 0 && <p className="stat type-body-display-sans"><CountUp delay={0.4} useEasing={false} duration={countIncrementDuration * imageCount} end={imageCount}/><sup className="type-body"><span className="nbsp">&nbsp;</span>image{imageCount > 1 && 's'}</sup></p>}
              {videoCount > 0 && <p className="stat type-body-display-sans"><CountUp delay={0.4} useEasing={false} duration={countIncrementDuration * videoCount} end={videoCount}/><sup className="type-body"><span className="nbsp">&nbsp;</span>video{videoCount > 1 && 's'}</sup></p>}
            </div>
          </div>
          {mainImage && mainImage.asset && (
            <figure className="media-container image-figure -fullwidth">
              <Figure node={mainImage} quality={100}/>
              {mainImage.caption && (
                <div className="caption-wrapper">
                  <figcaption className="type-body">{mainImage.caption}</figcaption>
                </div>       
              )}              
            </figure>
          )}
          <div className="info-container">
            <div className="info-col">
              {projectWebsite && <a className="type-body link" href={projectWebsite} target="_blank">{projectWebsite.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]}</a>}
            </div>
            {categories && categories.length > 0 && (
              <div className="info-col">
                <h4 className="type-body -meta">My role</h4>
                <ul className="meta-list">
                  {categories.map(category => (
                    <li className="type-body" key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
            {members && members.length > 0 && (
              <div className="info-col">
                <h4 className="type-body -meta">Team</h4>
                <ul className="meta-list">
                  {members.map(member => {
                    const person = member.person;
                    return (
                      <li className={`type-body${person.company ? ' company' : ' person'}`} key={member._key}>
                        {
                          person.website ? (
                            <a href={person.website} target="_blank" className="link">{person.name}</a>
                            ) : (
                              person.name
                          )
                        }
                        {member.roles && (
                          <sup className="type-metadata">{member.roles}</sup>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
        <ContentSection>
          {_rawBody && <BlockContent blocks={_rawBody || []} className="project-body" />}
        </ContentSection>
      </Article>
      <RelatedProjects otherProjects={otherProjects} id={id} />
    </Container>
  )
}

export default Project
