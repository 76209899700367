import React from 'react'

function Decorator ({mark}) {
  return (
    <span className={`star-decorator${mark.className ? ` ${mark.className}` : ''}`}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 50 50" xmlSpace="preserve">
      <path class="st0" d="M100.7-8.2l-8.7,4.9v-5.6H63.2v21h28.9V6.6l8.7,4.9V-8.2z M90.1,10.2H65.2v-17h24.9v4.7v7.5V10.2z M98.7,8.1
        l-6.7-3.8v-5.2l6.7-3.8V8.1z"/>
      <g>
        <path class="st0" d="M24.5,46.7c-0.9-0.2-1.5-0.9-1.8-1.8l-1.8-6.1c-0.1-0.2-0.2-0.2-0.3-0.3c-0.1,0-0.2,0-0.4,0.1l-5,3.9
          c-0.8,0.6-1.8,0.8-2.6,0.3c-1.1-0.6-1.6-1.8-1.2-3l2.2-6.1c0.1-0.2,0-0.3-0.1-0.4c0-0.1-0.2-0.2-0.3-0.2l-6.5,0.2
          c-1.3,0-2.3-0.8-2.5-2.1c-0.1-0.9,0.4-1.8,1.1-2.3l5.3-3.6c0.2-0.1,0.2-0.3,0.2-0.3s0-0.2-0.2-0.3L5.3,21c-0.7-0.5-1.1-1.2-1.1-2
          c0-1.4,1.2-2.5,2.5-2.4l6.5,0.2c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0.1-0.2,0.1-0.4l-2.1-6c-0.3-0.8-0.2-1.8,0.4-2.5
          c0.9-1,2.3-1.1,3.3-0.3l5.1,3.9c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.3-0.3L22.7,5C23,4,23.9,3.3,25,3.3h0c1.1,0,2,0.7,2.3,1.7
          l1.8,6.2c0.1,0.2,0.2,0.2,0.3,0.3c0.1,0,0.2,0,0.4-0.1l5-3.9c0.8-0.6,1.8-0.8,2.6-0.3c1.1,0.6,1.6,1.8,1.2,3l-2.2,6.1
          c-0.1,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.3,0.2l6.5-0.2c1.3-0.1,2.5,1,2.5,2.5c0,0.8-0.5,1.5-1.1,2l-5.3,3.6
          c-0.2,0.1-0.2,0.3-0.2,0.3c0,0.1,0,0.2,0.2,0.3l5.3,3.6c0.7,0.5,1.2,1.3,1.1,2.1c-0.1,1.4-1.2,2.3-2.5,2.3l-6.5-0.2c0,0,0,0,0,0
          c-0.2,0-0.3,0.1-0.3,0.2c0,0.1-0.1,0.2-0.1,0.4l2.1,6c0.3,0.8,0.2,1.8-0.4,2.5c-0.9,1-2.3,1.1-3.3,0.3l-5.1-3.9
          c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.3,0.3L27.3,45C27,46.2,25.8,47,24.5,46.7z"/>
      </g>
      </svg>
    </span>
  )
}

export default Decorator
