import React from 'react';

const ScrollArrow = () => {
    return (
        <>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style={{enableBackground: 'new 0 0 50 50'}} xmlSpace="preserve">
                <polygon points="36.8,23 24.7,10 12.6,23 14.8,25 23.3,15.9 23.3,39.6 26.2,39.6 26.2,15.9 34.7,25 "/>
            </svg>
        </>
    )
}

export default ScrollArrow