import React, {useState} from 'react'
import {Link} from 'gatsby'
import NavigationTabs from './navigation-tabs'
import ScrollProgress from './scroll-progress'

const SiteHeader = (props) => {
  const activeClassName = '-active'
  return (
    <>
      <header className={`site-header${props.isHome ? ' -homepage' : ''}${props.showNav ? ' -nav-open' : ''}`}>
        <nav className='site-nav'>
          <ul className='nav-items'>
            <li className='nav-item tl top'>
              <Link to='/' activeClassName={activeClassName} className={`nav-link type-section${props.isHome ? ' hide-active-state' : ''}`}>
                Align Left
                <span className='tagline'>Design<br /> &amp; development</span>
              </Link>
            </li>
            <li className='nav-item tr top menu-trigger' onClick={props.onShowNav}>
              <h2 className='nav-link type-section'>
                Menu
              </h2>
            </li>
            { !props.isHome && (
            <>
              <li className='nav-item tr top'>
                <Link to='/work/' activeClassName={activeClassName} className='nav-link type-section'>Work</Link>
              </li>
              <li className='nav-item br bottom'>
                <Link to='/act/' activeClassName={activeClassName} className='nav-link type-section'>Act</Link>
              </li>
              <li className='nav-item bl bottom'>
                <Link to='/about/' activeClassName={activeClassName} className='nav-link type-section'>About</Link>
              </li>
            </>
            )}
          </ul>
        </nav>
      </header>
      {props.showScrollProgress && <ScrollProgress />}
      <NavigationTabs showNav={props.showNav} onShowNav={props.onShowNav} isHome={props.isHome} />
    </>
  )
}

export default SiteHeader
