import React from 'react'
import PreviousNextLink from './previous-next-link'

function RelatedProjects ({otherProjects, id}) {
  let returnValue = []
  otherProjects.map((project) => {
    if (project.id === id) {
      const currentProjectIndex = otherProjects.indexOf(project);
      if (currentProjectIndex === 0) {
        returnValue[0] = otherProjects[otherProjects.length - 1];
      } else {
        returnValue[0] = otherProjects[currentProjectIndex - 1];
      }
      if (currentProjectIndex === otherProjects.length - 1) {
        returnValue[1] = otherProjects[0];
      } else {
        returnValue[1] = otherProjects[currentProjectIndex + 1];
      }
    }
  })
  return (
    <div className="related-projects">
      {
      returnValue.map((project, index) => {
        return (
          <PreviousNextLink key={`${project.id}${index}`} project={project} prev={index === 0} />
        )
      })
      }
    </div>
  )
      
}

export default RelatedProjects
