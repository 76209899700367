import React, {useState} from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import { InView } from 'react-intersection-observer'

const Figure = ({node, maxWidth, square, caption, quality, preventLoadAnimation, colourUnderlay}) => {
  if (!node.asset) {
    return null
  }

  const width = maxWidth != undefined ? maxWidth : 2500;
  const height = square ? maxWidth : undefined;
  const imageAspectRatio = square ? 1 : node.asset.metadata.dimensions.aspectRatio;
  quality = quality ? quality : 90;
  caption = node.caption || caption;
  const [imageLoaded, setimageLoaded] = useState(false);
  let dark = false;

  function handleOnLoad() {
    setimageLoaded(true);
  }

  function generateUrl (width, height, size = 1, returnWidth = true) {
    let urlString
    if (height != undefined) {
      urlString = imageUrlFor(buildImageObj(node))
        .width(Math.round(width * size))
        .height(Math.round(height * size))
        .quality(quality)
        .url()
    } else {
      urlString = imageUrlFor(buildImageObj(node))
        .width(Math.round(width * size))
        .quality(quality)
        .url()
    }

    return returnWidth ? `${urlString} ${Math.round(width * size)}w` : urlString
  }
  const src = generateUrl(width, square, 1, false)
  const srcset = `
    ${generateUrl(width, height, 0.25)},
    ${generateUrl(width, height, 0.5)},
    ${generateUrl(width, height, 0.75)},
    ${generateUrl(width, height)}` 

  const style = {
    paddingTop: `calc(100% / ${imageAspectRatio})`
  }

  const onInViewChange = (inView) => {
    setTimeout(() => {
      if (inView && !imageLoaded) {
        handleOnLoad();
      }
    }, 1500);
  }

  if (node.asset.metadata.palette) {
    const c = node.asset.metadata.palette.dominant.background.substring(1);      // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (luma < 40) {
      dark = true;
    }
    style.backgroundColor = node.asset.metadata.palette.dominant.background;
  }

  return (
    <InView
      as="figure" 
      onChange={(inView) => onInViewChange(inView)}
      className={`media-container image-figure${node.fullWidth ? ' -fullwidth' : ''}${node.isPortrait ? ' -portrait' : ''}${!preventLoadAnimation && imageLoaded ? ' -loaded' : ''}${dark ? ' -dark' : ''}`} 
      style={style}>
      {colourUnderlay && (
        <div className="colour-underlay" style={style}></div>
      )}
      <img
        sizes={`(max-width: ${width}px) 100vw, ${width}px`}
        srcSet={srcset}
        src={src}
        alt={node.alt}
        loading='lazy'
        width={width}
        onLoad={handleOnLoad}
      />
      {caption && (
        <div className="caption-wrapper">
          <figcaption className="type-body">{caption}</figcaption>
        </div>       
      )}
    </InView>
  )
}

export default Figure;







