import React, {useRef, useEffect, useState} from 'react'
import {inBrowser} from '../lib/helpers'

const GlowStrip = () => {
  const strip = useRef()
  const glow = useRef()
  const [scrollDistance, setScrollDistance] = useState(0)
  const handleScroll = () => {
    if (glow.current) {
      glow.current.style.transform = `translateY(${strip.current.getBoundingClientRect().top}px)`
    }
    setScrollDistance(inBrowser() ? `${Math.round(window.scrollY).toLocaleString()}px` : undefined)
  }
  useEffect(() => {
    setScrollDistance(inBrowser() ? `${Math.round(window.scrollY).toLocaleString()}px` : undefined)
    if (inBrowser()) {
      window.addEventListener('scroll', () => {
        handleScroll()
      })
    }
  }, [])
  return (
    <section ref={strip} className='glow-strip'>
      <div ref={glow} className='glow-center' />
      <div className='scroll-stats'>
        <p className='type-body'>The end,</p>
        <h2 className='type-body-display-sans'>{scrollDistance}</h2>
        <p className='type-body'>later.</p>
      </div>
    </section>
  )
}

export default GlowStrip
