import React, {useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import favicon from '../static/img/favicon.png'

export const siteThemes = ['green', 'blue', 'red', 'grey'];

function SEO ({description, lang, meta, keywords, title, themeColour, SEOMetadata, noindex}) {
  const themes = siteThemes;
  const randomiseTheme = () => {
    if (themeColour) {
      return themeColour
    } else {
      return themes[Math.floor(Math.random() * Math.floor(themes.length))]
    }
  }
  const [currentTheme] = useState(randomiseTheme())
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = SEOMetadata && SEOMetadata.description || (data.site && data.site.description) || ''
        const siteTitle = (data.site && data.site.title) || ''
        const metaTitle = SEOMetadata && SEOMetadata.title || title || ''
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || ''
        return (
          <Helmet
            htmlAttributes={{lang, 'data-theme': currentTheme}}
            title={metaTitle}
            titleTemplate={metaTitle === siteTitle ? '%s' : `%s | ${siteTitle}`}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: metaTitle
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: siteAuthor
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', ')
                  }
                  : []
              )
              .concat(meta)}
          >
            <link rel="icon" href={favicon} />
            {noindex && (
              <meta name="robots" content="noindex"></meta>
            )}
            <meta name="google-site-verification" content="-8ngtyy9wfcFn9DtpqF0xrueR9Vt0sFXKsrupvmJ3Dg" />
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-119882617-1"></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'UA-119882617-1');
              `}
            </script>
            
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: {eq: "siteSettings"}) {
      title
      description
      keywords
    }
  }
`
