import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

export const query = graphql`
  query ContactDetailsQuery {
    page: sanityAboutPage(_id: {regex: "/(drafts.|)aboutPage/"}) {
      instagramUrl
      instagramUsername
      dribbbleUrl
      dribbbleUsername
      email
      phone
    }
  }
`

const ContactDetails = () => (
    <StaticQuery
        query={query}
        render={
            (data) => {
                const page = data.page
                return (
                    <aside className="contact-details">
                        <ul className="contact-links">
                        {page.instagramUrl && (
                            <li className="item"><a href={page.instagramUrl} className="link">(i) {page.instagramUsername}</a></li>
                        )}
                        {page.dribbbleUrl && (
                            <li className="item"><a href={page.dribbbleUrl} className="link">(d) {page.dribbbleUsername}</a></li>
                        )}
                        {page.phone && (
                            <li className="item"><a href={`tel:${page.phone}`} className="link">(p) {page.phone}</a></li>
                        )}
                        {page.email && (
                            <li className="item"><a href={`mailto:${page.email}`} className="link">(e) {page.email}</a></li>
                        )}
                        </ul>
                    </aside>
                )
            }
        }
    >
    </StaticQuery>
);

export default ContactDetails