import React, {useRef, useEffect, useState} from 'react';
import { inBrowser, isMobile } from '../lib/helpers';
import ScrollArrow from './scroll-arrow'

const ScrollProgress = () => {
    const circle = useRef();

    let $d = inBrowser() ? document : undefined,
        $circ,
        init,
        wh,
        events = ['DOMContentLoaded', 'load', 'resize'], 
        top,
        perc,
        h,
        sHeight;

    const [started, setStarted] = useState(false);

    function trigger(eventName) {
        var event = $d.createEvent('HTMLEvents');
        event.initEvent(eventName, true, false);
        if (inBrowser()) {
            window.dispatchEvent(event);
        }
    }

    function updateProgress(perc, top) {
        var circle_offset = 126 * perc;
        setStarted(top > 200)
        if ($circ) {
            $circ.style.strokeDashoffset = 126 - circle_offset;
        }
    }

    function setSizes() {
        wh = inBrowser() ? window.innerHeight : undefined;
        h  = $d.body.offsetHeight;

        sHeight = h - wh;
    }

    function handler() {
        setSizes();
        trigger('scroll');
    }
    function handleClick() {
        let lastScrollY;
        const scrollToTop = () => {
            const c = inBrowser() ? (window.pageYOffset || $d.documentElement.scrollTop) : undefined;
            if (lastScrollY < c) {
                return
            }
            if (c > 0 && inBrowser()) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 14);
            }
            lastScrollY = inBrowser() ? (window.pageYOffset || $d.documentElement.scrollTop) : undefined;
        };
        scrollToTop();
    }

    init = function () {
        $circ = circle.current;
        setSizes();
        if (inBrowser()) {
            window.addEventListener('scroll', scrollHandler, false);
            events.map(function (event) {
                window.addEventListener(event, handler, false);
            });
        }

    };
    
    const scrollHandler = (win) => {
        top  = win.pageYOffset || $d.documentElement.scrollTop;
        perc = Math.max(0, Math.min(1, top / sHeight));

        updateProgress(perc, top);
    }

    useEffect(() => {
        if (!isMobile()) {
            init();
        }
        return function() {
            if (inBrowser()) {
                window.removeEventListener('scroll', scrollHandler)
            }
        }
    }, []);

    return (
        <div className={`progress-indicator${started ? ' -started' : ''}`} onClick={handleClick}>
            <svg className="progress-svg">
                <g>
                    <circle cx="0" cy="0" r="20" ref={circle} className="animated-circle" transform="translate(50,50) rotate(-90)"  />
                </g>
            </svg>
            <span className="up-arrow">
                <ScrollArrow />
            </span>
        </div>
    )
}

export default ScrollProgress