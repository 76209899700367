import React from 'react'
import {Link} from 'gatsby'
import {getProjectUrl} from '../lib/helpers'

function PreviousNextLink (props) {
  return (
    <div key={props.project.id} className={`related-project ${props.prev ? 'prev-project' : 'next-project' }`}>
      <Link to={getProjectUrl(props.project.slug.current)} className="related-link">
        <div className="name-transforms type-body-display-sans">
          <p className="name-transform type-body-display-sans">{props.project.title}</p>
          <p className="name-transform type-body-display-sans">{props.prev ? 'Previous Project' : 'Next Project' }</p>
        </div>
        <div className="label-transforms type-body">
          <p className="label-transform type-body">{props.prev ? '←' : '→' }</p>
          <p className="label-transform type-body">{props.prev ? 'Previous Project' : 'Next Project' }</p>
        </div>
      </Link>
    </div>
  )
}

export default PreviousNextLink
