import React, {useState} from 'react'
import {buildVideoObj} from '../lib/helpers'
import VideoIcon from './icon/video-icon';


export default ({node}) => {
  const url = buildVideoObj(node.asset).asset.url;
  const [videoLoaded, setVideoLoaded] = useState(false);
  function handleOnLoad() {
    setVideoLoaded(true);
  }   
  return (
    <div className={`media-container video-container${videoLoaded ? ' -loaded' : ''}${node.fullWidth ? ' -fullwidth' : ''}`}>
      <video src={url} autoPlay={true} loop={true} muted={true} onPlay={handleOnLoad}></video>
      {node.caption && (
        <div className="caption-wrapper">
          <figcaption className="type-body">{node.caption}</figcaption>
        </div>       
      )}   
      <VideoIcon />  
    </div> 
  )
}