import React from 'react'

const VideoIcon = () => {
	return (
		<svg className="video-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 50 50" xmlSpace="preserve">
			<path className="st0" d="M43.8,15.2l-8.7,4.9v-5.6H6.2v21h28.9v-5.6l8.7,4.9V15.2z M33.1,33.5H8.2v-17h24.9v4.7v7.5V33.5z M41.8,31.4
			l-6.7-3.8v-5.2l6.7-3.8V31.4z"/>
		</svg>
	)
}

export default VideoIcon
