import {format, isFuture} from 'date-fns'
import {getFile} from '@sanity/asset-utils'
import clientConfig from "../../client-config";
import {v4 as uuidv4} from 'uuid'
const globalProjectsPath = require('../../gatsby-node').globalProjectsPath();

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({slug}) {
  return (slug || {}).current
}

export function filterOutUnlistedDocs ({unlisted}) {
  return !unlisted
}

export function filterOutListedDocs ({unlisted}) {
  return unlisted
}

export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
  return !isFuture(publishedAt)
}

export function getBlogUrl (publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function inBrowser () {
  return typeof window !== `undefined` && typeof document !== `undefined`;
}

export function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


export function getProjectUrl (slug) {
  return `/${globalProjectsPath}/${slug.current || slug}/`
}

export function toPlainText(blocks = []) {
  if (blocks) {
    return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children, 
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the paragraphs leaving split by two linebreaks
    .join('\n\n')
  }
}

export function getSEOMetadata(dataObject, descriptionTextField) {
  dataObject = dataObject;
  const data = dataObject.seoFields || undefined;
  let metadata = {};
  metadata.title = data && data.metaTitle || dataObject.title,
  metadata.description = data && data.metaDescription || descriptionTextField && dataObject[descriptionTextField] && toPlainText(dataObject[descriptionTextField]).split("\n")[0],
  metadata.image = data && data.metaImage && data.metaImage.asset.url
  return metadata
}

export function getRelativeUrl (slug) {
  return `/${slug.current || slug}`
}

export function buildImageObj (source) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id}
  }
  
  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function generateKey(pre) {
  return `${pre}_${uuidv4()}`;
}

export function isMobile () {
  return inBrowser() ? window.matchMedia('(max-width: 768px)').matches : undefined;
}

export function buildVideoObj (source) {
  return getFile(source, clientConfig.sanity);
}
