import React, {useState, useEffect} from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import moment from 'moment'

import {getRelativeUrl} from '../lib/helpers'

const query = graphql`
query FooterQuery {
    page: sanityAboutPage(_id: {regex: "/(drafts.|)aboutPage/"}) {
      title
      instagramUrl
      instagramUsername
      dribbbleUrl
      dribbbleUsername
      email
      phone
    }
    blm: sanityBlmPage(_id: {regex: "/(drafts.|)blmPage/"}) {
        slug {
            current
        }
    }
  }
`

const SiteFooter = () => {
  return (
    <StaticQuery
      query={query}
      render={
        (data) => {
          const page = data.page
          const blm = data.blm
          const startYear = '2020'
          return (
            <>
              <footer className='site-footer'>
                <div className='footer-contents'>
                  <div className='contact-info'>
                    <ul className='contact-links'>
                      {page.phone && (
                        <li className='item'><a href={`tel:${page.phone}`} className='link type-body-display-sans'>{page.phone}</a></li>
                      )}
                      {page.email && (
                        <li className='item'><a href={`mailto:${page.email}`} className='link type-body-display-sans'>{page.email}</a></li>
                      )}
                      {page.instagramUrl && (
                        <li className='item'><a href={page.instagramUrl} target='_blank' className='link type-body-display-sans'>{page.instagramUsername}</a></li>
                      )}
                    </ul>
                    <p className='type-body'>© {`${startYear}${(moment().format('YYYY') != startYear) ? ' — ' + moment().format('YYYY') : ''}`} Align Left Design Limited

                    </p>
                  </div>
                </div>
              </footer>
            </>
          )
        }
      }
    />
  )
}

export default SiteFooter
