import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {filterOutUnlistedDocs, getSEOMetadata, toPlainText} from '../lib/helpers'

export const query = graphql`
  query projectTemplateQuery($id: String!) {
    allProjects: sanityWorkPage(_id: {regex: "/(drafts.|)workPage/"}) {
      projects {
        id
        title
        unlisted
        slug {
          current
        }
      }
    }
    project: sanityProject(id: {eq: $id}) {
      id
      publishedAt
      year
      categories {
        _id
        title
      }
      unlisted
      projectWebsite
      mainImage {
        asset {
          _id
          metadata {
            dimensions {
              aspectRatio
            }
            palette {
              dominant {
                background
              }
            }
          }          
        }
        alt
        caption
      }
      title
      slug {
        current
      }
      _rawBody(resolveReferences: {maxDepth: 10})
      members {
        _key
        roles
        person {
          name
          website
          company
        }
      }
      seoFields {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const ProjectTemplate = props => {
  const {data, errors} = props
  const project = data && data.project
  const allProjects = data && data.allProjects
  return (
    <Layout showScrollProgress={true}>
      {errors && <SEO title='GraphQL Error' />}
      {project && <SEO themeColour="black" SEOMetadata={getSEOMetadata(project, '_rawBody')} noindex={project.unlisted} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project {...project} allProjects={allProjects} />}
    </Layout>
  )
}

export default ProjectTemplate
