import React from 'react'
import ContactDetails from './contact-details'
import {Link} from 'gatsby'

const NavigationTabs = ({showNav, isHome, onShowNav}) => {
  return (
    <>
      <div className={`navigation-tabs${showNav ? ' -visible' : ''}${isHome ? ' -on-homepage' : ''}`}>
        <div className='tabs'>
          <Link to='/work/' className='tab'>
            <h2 className='type-section'>Work</h2>
            <p className='label type-body'>The what</p>
          </Link>
          <Link to='/about/' className='tab'>
            <h2 className='type-section'>About</h2>
            <p className='label type-body'>The who</p>
          </Link>
          <Link to='/act/' className='tab'>
            <h2 className='type-section'>Act</h2>
            <p className='label type-body'>The why</p>
          </Link>
          <Link to='/colophon/' className='tab'>
            <h2 className='type-section'>Colophon</h2>
            <p className='label type-body'>The how</p>
          </Link>
        </div>
      </div>
      {showNav && (
        <div className='navigation-underlay' onClick={onShowNav} />
      )}
    </>
  )
}

export default NavigationTabs
