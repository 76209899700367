import React, {useRef, useEffect} from 'react'

const Container = ({children, className}) => {
  const containerRef = useRef();
  useEffect(() => {
    containerRef.current.classList.add('entered');
  }, []);
  return (
    <main ref={containerRef} className={`page-container${className ? ' ' + className : ''}`}>{children}</main>
  )
}

export default Container
