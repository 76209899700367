import React from 'react'
import SiteHeader from './site-header'
import SiteFooter from './site-footer'
import GlowStrip from './glow-strip'
import ResizeOverlay from './resize-overlay'

const Layout = ({children, onShowNav, showNav, siteTitle, hideGlowStrip, isHome, showScrollProgress}) => {
  const showGrid = false
  const grid = []
  for (let i = 0; i < 12; i++) {
    grid.push(<div className='debug-column' key={i} />)
  }
  return (
    <>
      <>{children}</>
      { (!hideGlowStrip && !isHome) && (
        <GlowStrip />
      )}
      {!isHome && (
        <SiteFooter />
      )}
      <SiteHeader showScrollProgress={showScrollProgress} isHome={isHome} siteTitle={siteTitle} onShowNav={onShowNav} showNav={showNav} />
      {/* <ResizeOverlay /> */}
      <div className='debug-grid'>
        {showGrid && grid}
      </div>
    </>
  )
}

export default Layout
